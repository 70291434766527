<template>
    <b-overlay :show='showLoader'>
        <validation-observer ref="addAuthorValidation">
            <b-form @submit.prevent>
                <b-card>
                    <b-row>
                        <b-col><h3>{{ $t("general.add_author") }}</h3></b-col>
                        <b-col class="text-right">
                            <b-button variant="secondary" class="ml-2" @click='$router.go(-1)'>{{ $t('general.go_back')}}</b-button>
                        </b-col>
                    </b-row>
                    <b-row class="mt-1">
                        <b-col cols='12' sm='6'>
                            <validation-provider
                                :name="$t('general.name')"
                                #default="{ errors }"
                                rules="required"
                            >
                                <label for='name'>{{ $t('general.name') }}</label>
                                <b-form-input v-model="name" name="name" id="name"></b-form-input>
                                <small class="text-danger">{{ errors[0] }}</small>
                            </validation-provider>
                        </b-col>
                        <b-col cols='12' sm='6' class="mt-1 mt-sm-0">
                            <validation-provider
                                :name="$t('general.lastname')"
                                #default="{ errors }"
                                rules="required"
                            >
                                <label for='lastname'>{{ $t('general.lastname') }}</label>
                                <b-form-input v-model="lastname" name="lastname" id="lastname"></b-form-input>
                                <small class="text-danger">{{ errors[0] }}</small>
                            </validation-provider>
                        </b-col>
                    </b-row>
                    <b-row class="mt-1">
                        <b-col cols='12' sm='6' class="mt-1 mt-sm-0">
                            <validation-provider
                                :name="$t('general.birthday')"
                                #default="{ errors }"
                                :rules="{required: true, regex:'^\\d{4}\\-(0?[1-9]|1[012])\\-(0?[1-9]|[12][0-9]|3[01])$'}"
                            >
                                <label for='date'>{{ $t('general.birthday') }}</label>
                                <b-input-group>
                                    <b-form-input id='date' v-model="birthday" placeholder="LLLL-MM-DD" autocomplete="off"/>
                                    <b-input-group-append>
                                        <b-form-datepicker v-model="birthday" :date-format-options="dateFormat" button-only right aria-controls="date"  start-weekday="1"></b-form-datepicker>
                                    </b-input-group-append>
                                </b-input-group>

                                <small class="text-danger">{{ errors[0] }}</small>
                            </validation-provider>
                        </b-col>
                        <b-col  cols='12' sm='6' class="mt-1 mt-sm-0">
                            <validation-provider
                                :name="$t('general.name')"
                                #default="{ errors }"
                                rules="email"
                            >
                                <label for='emailAddress'>{{ $t('general.emailAddress') }}</label>
                                <b-form-input v-model="emailAddress" name="emailAddress" id="emailAddress"></b-form-input>
                        
                                <small class="text-danger">{{ errors[0] }}</small>
                            </validation-provider>
                        </b-col>
                    </b-row>
                    <b-row>
                        <b-col class="text-right">
                            <b-button @click="validationForm" variant="primary" class="mt-2">{{ $t('general.add') }}</b-button>
                        </b-col>
                    </b-row>
                </b-card>
            </b-form>
        </validation-observer>
    </b-overlay>
</template>
<script>
    import {BCol, BRow, BOverlay, BCard, BForm, BButton, BFormInput, BFormDatepicker, BInputGroup, BInputGroupAppend } from 'bootstrap-vue'
    import {ValidationProvider, ValidationObserver} from 'vee-validate'
    import { required, email } from '@validations'

    export default {
        components: {BCol, BRow, BOverlay, BCard, BForm, BButton, BFormInput, BFormDatepicker, BInputGroup, BInputGroupAppend, ValidationProvider, ValidationObserver},
        data() {
            return {
                showLoader: false,
                dateFormat: { 'year': 'numeric', 'month': 'numeric', 'day': 'numeric' },
                name: '',
                lastname: '',
                birthday: '',
                emailAddress: '',
                required,
                email,
                isChangingPermitted: false
            }
        },
        methods: {
            validationForm() {
                this.$refs.addAuthorValidation.validate().then((response) => {
                    if (response) {
                        this.onAddAuthor()
                    }
                })
            },
            onAddAuthor() {
                const thisIns = this
                thisIns.showLoader = true

                const payload = {
                    Firstname: thisIns.name.charAt(0).toUpperCase() + thisIns.name.slice(1).toLowerCase(),
                    Lastname: thisIns.lastname.charAt(0).toUpperCase() + thisIns.lastname.slice(1).toLowerCase(),
                    Birthday: thisIns.birthday,
                    Email: thisIns.emailAddress
                }

                thisIns.$http.put('/v1/sola/avtorji', payload)
                    .then(() => {
                        thisIns.$printSuccess(`${thisIns.$t('general.author_successfully_added')}`)

                        thisIns.showLoader = false

                        setTimeout(() => {
                            thisIns.$router.go(-1)
                            // thisIns.$router.push({name: 'author-list'})
                        }, 500)
                    })
                    .catch(error => {
                        thisIns.$printError(`${thisIns.$t('general.add_error')}\n${error.message}`)
                        thisIns.showLoader = false
                    })
            },
            async checkIfChangeIsPermitted() {
                this.isChangingPermitted = await this.$checkIfChangeIsPermitted()

                if (!this.isChangingPermitted) this.$router.replace('/')

            }
        },
        async mounted() {
            await this.checkIfChangeIsPermitted()

        }
    }
</script>